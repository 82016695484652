 import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M4298 9780 c-319 -24 -686 -96 -1013 -197 -62 -19 -355 -128 -404
-150 -401 -181 -694 -357 -1010 -605 -149 -117 -420 -374 -551 -523 -181 -204
-380 -480 -511 -710 -260 -455 -420 -881 -519 -1384 -59 -303 -80 -556 -80
-991 0 -538 32 -840 131 -1243 173 -703 512 -1342 1000 -1887 406 -453 906
-819 1464 -1070 39 -18 84 -38 100 -45 194 -89 576 -209 828 -260 108 -21 299
-52 417 -67 148 -18 1120 -18 1280 0 717 83 1365 317 1963 709 359 236 661
507 981 883 12 14 44 54 71 90 27 36 55 71 62 78 29 30 283 423 283 438 0 2
-59 3 -132 2 l-131 -3 -75 -110 c-274 -403 -618 -765 -997 -1051 -561 -422
-1212 -704 -1910 -829 -380 -67 -910 -80 -1295 -30 -790 101 -1523 402 -2150
883 -525 402 -964 936 -1260 1532 -115 231 -228 520 -290 745 -12 44 -26 93
-31 109 -10 32 -51 217 -68 306 -81 420 -93 999 -31 1427 167 1155 769 2183
1690 2889 115 88 346 245 425 289 28 15 81 46 120 68 38 22 137 73 220 113
422 201 848 330 1325 399 247 35 699 45 975 20 161 -14 375 -44 495 -70 36 -7
90 -19 120 -25 82 -17 261 -68 375 -105 389 -128 745 -301 1084 -527 110 -74
331 -236 351 -258 3 -3 39 -34 80 -69 226 -190 530 -523 710 -778 l30 -43 135
0 c74 0 135 3 135 6 0 18 -250 368 -321 449 -14 17 -64 73 -110 125 -199 227
-494 493 -717 648 -42 29 -86 59 -97 67 -216 157 -633 374 -934 484 -268 100
-537 172 -794 216 -312 52 -386 58 -862 60 -247 2 -498 -1 -557 -5z"/>
<path d="M4648 7962 l-28 -17 0 -332 c0 -210 -4 -334 -10 -338 -14 -9 -12 -73
3 -78 17 -5 16 -209 -1 -215 -14 -4 -16 -42 -2 -42 6 0 10 -30 10 -70 0 -40
-4 -70 -10 -70 -6 0 -10 -33 -10 -80 0 -47 4 -80 10 -80 6 0 10 -27 10 -60 0
-33 -4 -60 -10 -60 -5 0 -10 -22 -10 -50 0 -27 5 -50 10 -50 15 0 13 -127 -2
-133 -10 -4 -10 -7 0 -15 7 -5 19 -42 25 -82 l11 -73 -27 -21 c-33 -27 -34
-49 -3 -69 l23 -16 -3 -233 c-11 -686 -10 -665 -39 -803 -15 -71 -36 -144 -46
-161 -10 -17 -22 -55 -26 -84 -8 -51 -16 -62 -54 -74 -12 -3 -19 -22 -24 -65
-6 -55 -9 -60 -33 -63 -16 -2 -31 -13 -39 -28 -7 -15 -24 -27 -40 -30 -21 -4
-29 -12 -33 -33 -3 -16 -15 -33 -30 -40 -13 -7 -27 -23 -30 -37 -4 -16 -15
-26 -37 -32 -18 -5 -35 -13 -38 -18 -3 -6 -15 -10 -26 -10 -12 0 -19 -7 -19
-18 0 -27 -20 -42 -55 -42 -19 0 -35 -7 -42 -19 -8 -13 -29 -21 -67 -25 -50
-7 -55 -10 -58 -34 -3 -23 -9 -28 -43 -34 -22 -4 -72 -7 -110 -7 -39 -1 -84
-5 -101 -10 -30 -8 -447 3 -491 14 -14 3 -23 13 -23 25 0 26 -7 29 -100 42
-60 9 -84 17 -91 30 -6 12 -21 18 -44 18 -18 0 -37 5 -40 10 -3 6 -15 10 -26
10 -12 0 -19 7 -19 19 0 11 -13 31 -30 44 -16 14 -30 32 -30 39 0 21 -21 38
-47 38 -16 0 -24 7 -29 28 -9 37 -23 52 -48 52 -21 0 -56 53 -56 85 0 7 -10
15 -22 17 -17 2 -24 12 -30 43 -5 22 -14 45 -21 50 -13 10 -31 68 -42 136 -4
28 -11 38 -29 43 -29 7 -33 33 -42 283 -5 126 -3 220 6 303 13 116 14 120 36
120 13 0 24 3 24 8 0 4 5 41 10 82 8 58 15 79 31 91 13 9 24 33 28 55 5 30 11
39 31 44 17 4 26 14 28 30 5 34 20 50 48 50 18 0 24 6 24 21 0 28 37 79 58 79
23 1 52 24 52 42 0 21 24 39 63 47 24 5 33 13 36 31 4 30 17 40 54 40 22 0 27
4 27 25 0 32 20 41 105 49 38 4 76 13 85 21 31 28 344 46 525 31 50 -4 110 -9
134 -11 24 -2 47 -8 50 -13 3 -5 31 -15 61 -22 30 -8 60 -17 66 -22 6 -5 18
-6 27 -2 11 4 16 -2 19 -23 3 -24 8 -29 42 -33 37 -5 48 -14 60 -52 3 -11 15
-18 29 -18 13 0 32 -7 43 -15 10 -8 28 -15 39 -15 13 0 16 -3 9 -10 -8 -8 2
-23 30 -52 61 -61 143 -72 129 -18 -3 11 -2 32 2 47 5 21 8 24 16 12 7 -12 9
-11 9 2 0 9 -7 19 -15 23 -11 4 -15 22 -15 73 0 76 8 115 21 107 5 -3 9 -1 9
5 0 5 -6 11 -12 13 -10 4 -14 86 -16 357 -2 346 -2 353 18 358 27 7 28 53 1
53 -22 0 -51 35 -51 61 0 12 -18 19 -76 29 -41 7 -86 21 -99 32 -18 14 -49 20
-127 26 -94 6 -104 9 -113 30 -5 12 -18 25 -27 28 -75 23 -841 19 -886 -5 -13
-7 -20 -16 -17 -20 11 -18 -24 -29 -115 -35 -71 -4 -102 -11 -122 -26 -14 -10
-48 -23 -75 -27 -36 -6 -51 -14 -58 -31 -5 -12 -17 -22 -25 -22 -9 0 -20 -4
-26 -9 -5 -5 -27 -11 -49 -13 -30 -2 -42 -9 -51 -26 -17 -35 -26 -42 -63 -46
-37 -4 -48 -12 -53 -41 -2 -15 -18 -22 -71 -34 -39 -9 -67 -20 -67 -28 0 -6
-15 -15 -34 -18 -29 -6 -35 -12 -41 -41 -4 -24 -11 -34 -24 -34 -20 0 -31 -19
-31 -50 0 -14 -9 -20 -32 -22 -29 -3 -34 -7 -38 -38 -4 -31 -9 -35 -38 -38
-24 -2 -35 -10 -42 -27 -6 -16 -18 -25 -37 -27 -24 -3 -28 -8 -31 -37 -2 -22
-10 -35 -22 -38 -11 -3 -21 -18 -24 -38 -5 -27 -12 -34 -33 -37 -21 -2 -28
-11 -39 -48 -8 -25 -14 -55 -14 -67 0 -18 -6 -23 -26 -23 -19 0 -28 -7 -37
-30 -8 -21 -18 -30 -34 -30 -29 0 -32 -7 -40 -80 -3 -34 -12 -68 -20 -76 -8
-7 -22 -51 -32 -96 -15 -72 -20 -83 -42 -88 -22 -5 -25 -13 -36 -100 -6 -52
-12 -96 -12 -97 -1 -2 -10 -3 -21 -3 -24 0 -27 -13 -35 -140 -8 -133 -6 -693
3 -765 6 -52 10 -60 29 -63 27 -4 29 -14 38 -131 6 -81 18 -117 36 -106 16 10
37 -40 44 -105 4 -45 13 -71 26 -83 10 -10 19 -30 19 -45 0 -51 11 -71 38 -74
22 -2 28 -10 35 -43 4 -22 10 -52 13 -66 4 -20 14 -28 35 -33 23 -4 29 -11 29
-30 0 -42 10 -56 41 -56 22 0 29 -5 29 -18 0 -11 9 -27 20 -37 11 -10 20 -25
20 -34 0 -26 92 -119 120 -123 16 -2 26 -10 28 -23 5 -24 31 -55 46 -55 6 0
19 -14 29 -31 9 -17 26 -33 37 -36 11 -3 20 -11 20 -18 0 -15 22 -25 55 -25
19 0 24 -6 27 -32 3 -30 6 -33 41 -36 33 -3 39 -7 44 -32 5 -27 10 -29 62 -36
65 -7 81 -17 81 -49 0 -23 29 -35 86 -35 13 0 28 -9 33 -19 8 -15 28 -21 83
-28 62 -7 74 -11 84 -32 18 -35 28 -39 133 -46 98 -7 124 -16 115 -39 -3 -8 2
-16 11 -20 36 -14 461 -27 655 -21 256 9 284 13 288 43 2 12 6 23 10 23 10 2
42 5 128 13 87 8 104 13 104 32 0 18 54 41 115 50 28 4 55 13 60 21 6 8 22 16
35 18 111 17 109 17 112 43 2 21 12 29 48 40 32 10 51 23 62 43 14 23 24 29
52 29 32 0 36 3 42 35 5 30 10 35 35 35 19 0 34 8 44 24 9 13 25 26 35 29 11
3 25 18 31 34 10 26 12 27 19 8 7 -15 9 -16 9 -4 1 9 7 22 15 28 8 7 18 26 21
42 5 21 12 29 29 29 28 0 46 18 46 46 0 15 8 24 27 29 32 8 43 21 43 53 0 15
6 22 19 22 33 0 51 11 51 32 0 11 8 22 20 25 14 4 20 14 20 32 0 33 18 58 44
63 12 2 23 18 32 47 7 24 13 47 12 50 -3 29 5 41 26 41 26 0 46 39 46 91 0 23
5 28 27 31 24 3 29 9 35 43 4 22 11 56 15 75 3 19 12 40 18 47 7 6 17 60 24
120 16 132 16 133 40 133 39 0 42 112 39 1470 -3 1347 -2 1320 -48 1320 -21 0
-26 12 -35 87 -5 39 -13 56 -30 67 -13 9 -25 28 -27 43 -2 22 -9 29 -33 33
-21 4 -31 12 -33 27 -3 17 -11 23 -40 25 -32 3 -38 7 -42 33 -5 27 -10 31 -60
40 -45 9 -55 14 -60 35 -7 29 -24 33 -184 49 -164 16 -275 14 -308 -7z"/>
<path d="M5751 7078 l1 -63 15 48 c15 49 12 77 -7 77 -6 0 -10 -26 -9 -62z"/>
<path d="M4410 5939 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
</g>
</svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}